import { Helmet } from "react-helmet";
import { SelectBox, Img, Text, Input, Heading } from "../../components";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import React, { Suspense, useEffect, useState } from "react";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import { useNavigate, useParams } from "react-router-dom";
import AdminCatalogueRowonehundredfo from "components/AdminCatalogueRowonehundredfo";
import { Link } from "react-router-dom";
import { getStatistiqueSequences, getStatistiqueSequencesFormat, getStatistiqueSequencesUsers } from "services/adminServices";
import HLSSource from "pages/Editermonextrait/HLSSource";
import Footer from "components/Footer";
import { DataTable } from 'primereact/datatable'; // Import PrimeReact DataTable
import { Column } from 'primereact/column'; // Import PrimeReact Column
import "primereact/resources/themes/saga-blue/theme.css"; // Thème PrimeReact
import "primereact/resources/primereact.min.css"; // Styles de base PrimeReact
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';


const dataSequencesFormat = [
  { name: 'Vertical', value: 0 },
  { name: 'Horizontal', value: 0 },
  { name: 'Carré', value: 0 },
  { name: 'Original', value: 0 }
];

const dataSequencesFormat2 = [
  {
    subject: 'Vertical',
    A: 120,
    B: 10,
    fullMark: 0,
  },
  {
    subject: 'Horizontal',
    A: 120,
    B: 10,
    fullMark: 0,
  },
  {
    subject: 'Carré',
    A: 120,
    B: 10,
    fullMark: 0,
  },
  {
    subject: 'Original',
    A: 120,
    B: 10,
    fullMark: 0,
  }
];
const dataTemplate = [
  { image: "/images/img_light_bulb.svg", count: "0", label: "Téléchargements" }
];

const getCurrentDateTime = () => {
  const now = new Date();

  // Format the date and time components
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  // Combine to get the final formatted string
  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};
export default function AdminStatistiquePageSequence() {
  const { id,idfilm } = useParams();// Récupère l'ID du film à partir des paramètres de la route
  const appUrl = process.env.REACT_APP_URL; // Récupère l'URL de base de l'application
  const [dataSequence, setDataSequence] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); // Gère les erreurs potentielles
  const [dataUsers, setDataUsers] = useState(null);
  const navigate = useNavigate(); // Initialize the navigate function
  const [filterText, setFilterText] = useState(""); // State to store filter text
  const fetchStatistique = async () => {
    try {
      // setLoading(true);
      const data = await getStatistiqueSequences(id);
      const dataFormat = await getStatistiqueSequencesFormat(id);
      dataFormat.sequences.map((item)=>{
        if(item.formatSequence=="vertical"){
          dataSequencesFormat[0].value = item.nb;
          dataSequencesFormat2[0].A = item.nb;
        }
        if(item.formatSequence=="horizontal"){
          dataSequencesFormat[1].value = item.nb;
          dataSequencesFormat2[1].A = item.nb;
        }
        if(item.formatSequence=="square"){
          dataSequencesFormat[2].value = item.nb;
          dataSequencesFormat2[2].A = item.nb;
        }
        if(item.formatSequence=="original"){
          dataSequencesFormat[3].value = item.nb;
          dataSequencesFormat2[3].A = item.nb;
        }
        console.log(item); 
      });
      setDataSequence(data);
      
      dataTemplate[0].count=data.userSequences;
      const resdataUsers = await getStatistiqueSequencesUsers(id);
      setDataUsers(resdataUsers);
    } catch (err) {
      setError("Erreur lors du chargement des statistiques.");
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    
    fetchStatistique();
    setInterval(() => {
      fetchStatistique();
    }, 2000);
    // Pas besoin de cleanup function si elle n'est pas utilisée
  }, []); // Ce useEffect ne fait rien pour l'instant, mais peut être retiré si inutile



  const filteredData = dataUsers?.filter(item => 
    item.email.toLowerCase().includes(filterText.toLowerCase()) || 
    item.createdAt.toLowerCase().includes(filterText.toLowerCase())
  );

  if (loading) {
    return <div className="loader">Chargement en cours...</div>; // Affiche un loader pendant le chargement
  }

  if (error) {
    return <div className="error">{error}</div>; // Affiche un message d'erreur en cas d'échec de l'appel API
  }

  // Fonction pour exporter les données sous forme de CSV
  const exportCSVUser = () => {
    const csvRows = [];
    const headers = ['Email', 'Cree le', 'Nombre de clips', 'Duree des clips (s)'];
    csvRows.push(headers.join(';')); // Ajouter les en-têtes

    // Ajouter les lignes des données
    filteredData.forEach((user) => {
      const row = [
        user.email,
        user.createdAt,
        user.sequenceCount,
        user.totalDuration
      ];
      csvRows.push(row.join(';'));
    });

    // Créer un Blob à partir des données CSV
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    const current_datetime = getCurrentDateTime();
    a.setAttribute('download', 'E-'+dataSequence.sequence.title+'users_statistiques-'+current_datetime+'.csv');
    a.click();
  };

  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta 
          name="description" 
          content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." 
        />
      </Helmet>
      
      <div className="relative w-full">
        <div className="flex flex-col z-[5] gap-[27px]">
          {/* Navigation bar spécifique à cette page */}
          <AdminGestionPageDistributeurNavbar />

          <div className="ml-[54px] flex w-[90%] md:w-[100%] items-start gap-2.5 md:ml-0 md:w-full md:p-5">
            {/* Sidebar de navigation */}
            <Sidebar />
            {dataSequence && (
            <div className="mt-12 flex w-[80%] md:w-[100%] flex-col gap-[60px] sm:gap-[30px]">
              <div className="flex w-[80%] md:w-[100%] flex-col items-end gap-[55px] md:w-full md:p-5 sm:gap-[27px]">
                <div className="self-stretch relative">
                  <Img
                    src={`${appUrl}public/films/${idfilm}.jpg`}
                    className="rounded-[10px] h-[250px] w-[100%] object-cover"
                    alt={"Film Image"}
                  />
                  <div className="absolute md:relative top-[20px] left-[20px] flex w-[97%] gap-5 md:w-full md:flex-col">
                    <Suspense fallback={<div className="loader">Loading feed...</div>}>
                      {dataTemplate.map((d, index) => (
                        <AdminCatalogueRowonehundredfo
                          {...d}
                          key={"listonehundredf" + index}
                          className="w-[25%] md:w-full sm:p-5"
                        />
                      ))}
                    </Suspense>
                  </div>
                  <div className="absolute top-[20px] right-[20px] md:w-full md:flex-col">
                    <Link to={`/admin/statistique/film/${idfilm}`} >   
                      <Img
                        src={`${appUrl}public/films/${idfilm}-affiche.jpg`}
                        className="rounded-[10px] w-[150px]"
                        alt={"Film Image"}
                      />
                    </Link>
                  </div>
                </div>
                <div className="self-stretch relative">
                  <div className="mb-[20px]">
                    <Heading size="headline_2" as="h2">
                      {dataSequence.sequence.title}
                    </Heading>
                    <Text as="p">{dataSequence.sequence.subtitle}</Text>
                    <Heading size="headline_3" as="h3">
                      Durée moyenne des Clips "{dataSequence.averageTimeStart} à {dataSequence.averageTimeEnd}"
                    </Heading>
                  </div>
                  <HLSSource 
                    dataTimeClipVideo={{timeStart:dataSequence.averageTimeStart,timeEnd:dataSequence.averageTimeEnd}}
                    disabledClipVideo={false}
                    control={false}
                    src={appUrl+"public/hls/sequence_"+dataSequence.sequence.id+"/720p.m3u8"}
                  />
                  <div style={{ width: '100%', height: 400 }} className="flex  md:flex-col">
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          dataKey="value"
                          isAnimationActive={false}
                          data={dataSequencesFormat}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        />
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                    <ResponsiveContainer width="100%" height="100%">
                      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={dataSequencesFormat2}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="subject" />
                        <PolarRadiusAxis />
                        <Radar name="Mike" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                      </RadarChart>
                    </ResponsiveContainer>
                  </div>

                </div>
                {/* Ajout du tableau de données au-dessus du carousel */}
                <Heading size="headline_3" as="h3" className="mt-[50px] mb-[20px] text-center w-[100%]">
                  Statistiques Utilisateurs
                </Heading>
                <div className="self-stretch mb-5">
                    {/* Filtre de recherche */}
                    <div className="mb-3 flex">
                      <input
                        style={{border:"solid 1px #fff",padding:"20px",color:"#fff",borderRadius:"10px"}}
                        type="text"
                        placeholder="Filtrer par email ou date"
                        value={filterText}
                        onChange={e => setFilterText(e.target.value)}
                        className="p-2 border rounded-md w-[calc(100% - 50px)]"
                      />
                      <div className="flex items-center justify-center transform transition duration-500 hover:rotate-180 cursor-pointer ml-[10px]">
                        <Img
                          src="/images/img_settings_default_white.svg"
                          alt="settings"
                          className="h-[40px] sm:h-[40px] object-cover" 
                        />
                      </div>
                    </div>
                    
                    {/* Utilisation de PrimeReact DataTable */}
                    <DataTable 
                      value={filteredData} 
                      paginator 
                      loading={loading}
                      rows={10} 
                      className="p-datatable-striped"
                      style={{ backgroundColor: 'transparent', color: '#fff' ,textAlign:"center" }}
                    >
                      <Column 
                        field="email" 
                        className="overflow-hidden" 
                        header="Email" 
                        body={(rowData) => (
                          <Link to={`/admin/statistique/user/${rowData.userId}?film=${idfilm}&sequence=${id}`}
                          style={{ backgroundColor: 'transparent', border: 'none', color: '#fff', cursor: 'pointer' }}
                        >
                          {rowData.email}
                        </Link>
                        )}
                        sortable
                      />
                      <Column field="createdAt" className="overflow-hidden"  header="Créé le" sortable />
                      <Column field="sequenceCount" className="overflow-hidden"  header="Nombre de clips" body={(rowData) => `${rowData.sequenceCount}`} sortable className="text-center" />
                      <Column field="totalDuration" className="overflow-hidden"  header="Durée des clips (s)" body={(rowData) => `${rowData.totalDuration}`} sortable className="text-center" />
                    </DataTable>
                  </div>
                   {/* Bouton pour exporter le CSV */}
                   <div className="mb-3 text-center">
                      <button 
                        onClick={exportCSVUser} 
                        class="btn-clip"
                        style={{ padding: "10px 20px", color: "white", borderRadius: "5px", cursor: "pointer" }}
                      >
                        Télécharger CSV
                      </button>
                    </div>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
