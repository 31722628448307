import { Img } from 'components';
import MenuMenuclient from 'components/MenuMenuclient';
import React, { useState, useRef, useEffect } from 'react';
import { getprofil } from 'services/authServices';
import 'video-react/dist/video-react.css';

const MenuClient = ({afficherMenuClient}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isImageMenu, setIsImageMenu] = useState(true);
  const menuRef = useRef(null);
  const toggleMenu = () => setIsMenuOpen(prev => !prev);
  const [imageUrl, setImageUrl] = useState(getprofil());
  const handleImageError = () => {
    // setImageUrl("/images/icons/profile.png");
    setIsImageMenu(false)
  };
  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={menuRef}> 
      {isImageMenu &&
        <a href={!afficherMenuClient?"/connexionseaux":"#"} onMouseEnter={toggleMenu}  onClick={toggleMenu} className="w-[50px] relative h-[50px] rounded-full flex justify-center items-center overflow-hidden">
          <Img
            src={imageUrl}
            alt="image"
            className="h-[50px] w-[50px]  object-cover"
            onError={handleImageError}
          />
        </a>
      }
      {isMenuOpen && afficherMenuClient && (
        <div className="absolute menumenuclient top-[55px] right-[0px] z-[99999] flex max-w-max flex-col items-start rounded-lg bg-cover bg-no-repeat p-[35px] md:mr-0 md:h-auto md:w-full sm:p-5">
          <MenuMenuclient />
        </div>
      )}
    </div>
  );
};

export default MenuClient;
