import { Text, Img, Heading } from "./..";
import React from "react";

export default function AdminCatalogueCardsquence1({ ...props }) {
  return (
    <div {...props} className={`${props.className} flex flex-col w-full sm:w-full gap-3`}>
      <div className="relative h-[161px] self-stretch">
        <Img src="/images/img_rectangle_91_2.png" alt="image" className="h-[161px] w-full rounded-[7px] object-cover" />
        <div className="absolute right-[8.00px] top-[8.00px] m-auto flex w-[28%] justify-end gap-[3px]">
          <div className="flex w-full rounded bg-black-900_bf p-[3px]">
            <Img src="/images/img_star_10_120.svg" alt="image" className="h-[11px] w-[11px]" />
            <Img src="/images/img_star_11_93.svg" alt="image" className="h-[11px] w-[11px]" />
          </div>
          <Text size="caption" as="p" className="flex items-center justify-center rounded bg-black-900_bf px-1 py-px">
            02:30
          </Text>
        </div>
      </div>
      <div className="mb-3.5 flex w-[77%] flex-col items-start gap-3">
        <Heading as="p">L’audition</Heading>
        <div className="flex flex-wrap items-center self-stretch">
          <Img src="/images/img_arrow_down_circle.svg" alt="image" className="h-[16px] w-[16px]" />
          <Text as="p" className="ml-[5px] !text-gray-500">
            126
          </Text>
          <Img src="/images/img_film_gray_500.svg" alt="image" className="ml-3.5 h-[16px] w-[16px]" />
          <Text as="p" className="ml-1.5 !text-gray-500">
            Les Choristes (2004)
          </Text>
        </div>
      </div>
    </div>
  );
}
