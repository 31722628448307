import { Img } from "./..";
import React from "react";
import { MenuItem, Menu, Sidebar, sidebarClasses } from "react-pro-sidebar";

export default function Sidebar4({ ...props }) {
  const [collapsed, setCollapsed] = React.useState(false);

  //use this function to collapse/expand the sidebar
  //function collapseSidebar() {
  //    setCollapsed(!collapsed)
  //}

  return (
    <Sidebar
      {...props}
      width="374px !important"
      collapsedWidth="80px !important"
      collapsed={collapsed}
      rootStyles={{ [`.${sidebarClasses.container}`]: { gap: 27 } }}
      className={`${props.className} flex flex-col h-screen pr-11 gap-[27px] top-0 md:pr-5 !sticky overflow-auto`}
    >
      <div className="flex w-[51%] justify-end p-7 sm:p-5">
        <Img
          src="images/img_settings.svg"
          alt="sidebarlogo"
          className="mr-4 mt-[15px] h-[28px] w-[49px] object-contain"
        />
      </div>
      <Menu
        menuItemStyles={{
          button: {
            padding: "19px 23px",
            gap: "20px",
            color: "#ffffff",
            fontWeight: 400,
            fontSize: "16px",
            borderRadius: "8px",
            [`&:hover, &.ps-active`]: { fontWeight: "500 !important", backgroundColor: "#6342f5 !important" },
          },
        }}
        className="w-full"
      >
        <MenuItem icon={<Img src="images/img_grid.svg" alt="grid" className="h-[26px] w-[23px]" />}>Dashboard</MenuItem>
        <MenuItem icon={<Img src="images/img_folder.svg" alt="folder" className="h-[26px] w-[23px]" />}>
          Mon catalogue
        </MenuItem>
        <MenuItem icon={<Img src="images/img_analytics.svg" alt="analytics" className="h-[23px] w-[23px]" />}>
          Analyse
        </MenuItem>
        <MenuItem icon={<Img src="images/img_laptop.svg" alt="laptop" className="h-[26px] w-[23px]" />}>
          Gestion de la page d’accueil
        </MenuItem>
        <MenuItem icon={<Img src="images/img_hourglass.svg" alt="hourglass" className="h-[26px] w-[23px]" />}>
          Gestion des droits
        </MenuItem>
        <MenuItem icon={<Img src="images/img_add.svg" alt="add" className="h-[24px] w-[24px]" />}>
          Uploader une séquence
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}
