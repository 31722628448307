import { Helmet } from "react-helmet";
import { Img, Text, Heading } from "../../components";
import Sidebar from "../../components/Sidebar";
import React, { Suspense, useEffect, useState } from "react";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import AdminCatalogueRowonehundredfo from "components/AdminCatalogueRowonehundredfo";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getStatistiqueFilm } from "services/adminServices";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"; // Import du CSS pour le carousel
import { DataTable } from 'primereact/datatable'; // Import PrimeReact DataTable
import { Column } from 'primereact/column'; // Import PrimeReact Column
import "primereact/resources/themes/saga-blue/theme.css"; // Thème PrimeReact
import "primereact/resources/primereact.min.css"; // Styles de base PrimeReact
import Footer from "components/Footer";

const dataTemplate = [
  { image: "/images/img_film.svg", count: "0", label: "Séquences" },
  { image: "/images/img_light_bulb.svg", count: "0", label: "Téléchargements" },
  { image: "/images/icon-users.svg", count: "0", label: "Utilisateurs" }
];

const getCurrentDateTime = () => {
  const now = new Date();

  // Format the date and time components
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  // Combine to get the final formatted string
  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};
export default function AdminStatistiquePageFilm() {

  const { id } = useParams(); // Récupère l'ID du film à partir des paramètres de la route
  const [statistiqueFilm, setStatistiqueFilm] = useState(null); // Gère les données du film
  const [loading, setLoading] = useState(false); // Gère l'état de chargement
  const [error, setError] = useState(null); // Gère les erreurs potentielles
  const appUrl = process.env.REACT_APP_URL; // Récupère l'URL de base de l'application
  const [dataUsers, setDataUsers] = useState(null);
  const navigate = useNavigate(); // Initialize the navigate function
  const [filterText, setFilterText] = useState(""); // State to store filter text

  const fetchStatistique = async () => {
    try {
      //setLoading(true);
      const statistiqueFilmData = await getStatistiqueFilm(id);
      setStatistiqueFilm(statistiqueFilmData);
      dataTemplate[0].count = statistiqueFilmData.sequenceCount;
      dataTemplate[1].count = statistiqueFilmData.totalUserSequenceCount;
      dataTemplate[2].count = statistiqueFilmData.totalUserCount;
      setDataUsers(statistiqueFilmData.dataUserCount);
    } catch (err) {
      setError("Erreur lors du chargement des statistiques.");
    } finally {
      //setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatistique(); // Appelle la fonction lors du montage du composant
    setInterval(() => {
      fetchStatistique();
    }, 2000);
  }, [id]); // Re-exécute l'effet si l'ID change

  if (loading) {
    return <div className="loader">Chargement en cours...</div>; // Affiche un loader pendant le chargement
  }

  if (error) {
    return <div className="error">{error}</div>; // Affiche un message d'erreur en cas d'échec de l'appel API
  }

  // Configuration du carousel
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1650 }, items: 6 },
    desktop: { breakpoint: { max: 1650, min: 1250 }, items: 4 },
    tablet: { breakpoint: { max: 1250, min: 464 }, items: 3 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
  };

  const filteredData = dataUsers?.filter(item => 
    item.email.toLowerCase().includes(filterText.toLowerCase()) || 
    item.createdAt.toLowerCase().includes(filterText.toLowerCase())
  );

  // Fonction pour exporter les données sous forme de CSV
  const exportCSVUser = () => {
    const csvRows = [];
    const headers = ['Email', 'Cree le', 'Nombre de clips', 'Duree des clips (s)'];
    csvRows.push(headers.join(';')); // Ajouter les en-têtes

    // Ajouter les lignes des données
    filteredData.forEach((user) => {
      const row = [
        user.email,
        user.createdAt,
        user.sequenceCount,
        user.totalDuration
      ];
      csvRows.push(row.join(';'));
    });

    // Créer un Blob à partir des données CSV
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    const current_datetime = getCurrentDateTime();
    a.setAttribute('download', 'C-'+statistiqueFilm.filmTitle+'-users_statistiques-'+current_datetime+'.csv');
    a.click();
  };

  return (
    <>
      <Helmet>
        <title>ReCut - Statistiques du film</title>
        <meta name="description" content="Visionnage, téléchargement et statistiques des films sur la plateforme ReCut." />
      </Helmet>
      <div className="relative w-full">
        <div className="flex flex-col z-[5] gap-[27px]">
          <AdminGestionPageDistributeurNavbar />
          <div className="ml-[54px] flex w-[90%] md:w-[100%] items-start gap-2.5 md:ml-0 md:w-full md:p-5">
            <Sidebar />
            {statistiqueFilm && (
              <div className="mt-12 flex w-[80%] md:w-[100%] flex-col gap-[60px] sm:gap-[30px]">
                <div className="flex w-[80%] md:w-[100%] flex-col items-end gap-[55px] md:w-full md:p-5 sm:gap-[27px]">
                  <div className="self-stretch relative flex flex-col">
                    <Img
                      src={`${appUrl}public/films/${statistiqueFilm.filmId}.jpg`}
                      className="rounded-[10px] h-[250px] w-[100%] object-cover"
                      alt={statistiqueFilm.filmTitle || "Film Image"}
                    />
                    <div className="absolute md:relative top-[20px] left-[20px] flex w-[97%] gap-5 md:w-full md:flex-col">
                      <Suspense fallback={<div className="loader">Loading feed...</div>}>
                        {dataTemplate.map((d, index) => (
                          <AdminCatalogueRowonehundredfo
                            {...d}
                            key={"listonehundredf" + index}
                            className="w-[25%] md:w-full sm:p-5"
                          />
                        ))}
                      </Suspense>
                    </div>
                    <div className="absolute top-[20px] right-[20px] md:right-[-10px] md:w-full md:flex-col">
                      <Link to={`/admin/statistique/film/${id}`} >   
                        <Img
                          src={`${appUrl}public/films/${statistiqueFilm.filmId}-affiche.jpg`}
                          className="rounded-[10px] w-[150px] shadow-xl dark:shadow-gray-800"
                          alt={statistiqueFilm.filmTitle || "Film Image"}
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="self-stretch">
                    <Suspense fallback={<div className="loader">Loading feed...</div>}>
                      {Array.isArray(statistiqueFilm.sequences) && statistiqueFilm.sequences.length > 0 ? (
                        <Carousel responsive={responsive}>
                          {statistiqueFilm.sequences.map((sequence, index) => (
                            <Link
                              to={`/admin/statistique/film/${id}/sequence/${sequence.sequenceId}`}
                              key={index}
                            >
                              <div className="mb-3">
                                <Img
                                  src={`${appUrl}${sequence.urlimage}`}
                                  alt={sequence.title}
                                  className="h-[200px] w-[150px] object-cover rounded-[10px] max-w-max"
                                />
                              </div>
                              <div className="flex flex-1 flex-col items-start gap-2 sm:gap-1 w-[150px]">
                                <Heading
                                  size="subtitle_3"
                                  as="p"
                                  className="sm:text-sm limit-texte overflow-hidden"
                                >
                                  {sequence.title}
                                </Heading>
                                <Text as="p" className="!text-gray-500 sm:text-xs limit-texte">
                                  {sequence.userSequenceCount} séquences
                                </Text>
                              </div>
                            </Link>
                          ))}
                        </Carousel>
                      ) : (
                        <Text as="p" className="text-white sm:text-xs">
                          Aucune séquence disponible
                        </Text>
                      )}
                    </Suspense>
                  </div>
                  {/* Ajout du tableau de données au-dessus du carousel */}
                  <Heading size="headline_3" as="h3" className="mt-[50px] mb-[20px] text-center w-[100%]">
                    Statistiques Utilisateurs
                  </Heading>
                  <div className="self-stretch mb-5">
                    {/* Filtre de recherche */}
                    <div className="mb-3 flex">
                        <input
                          style={{border:"solid 1px #fff",padding:"20px",color:"#fff",borderRadius:"10px"}}
                          type="text"
                          placeholder="Filtrer par email ou date"
                          value={filterText}
                          onChange={e => setFilterText(e.target.value)}
                          className="p-2 border rounded-md w-[calc(100% - 50px)]"
                        />
                        <div className="flex items-center justify-center transform transition duration-500 hover:rotate-180 cursor-pointer ml-[10px]">
                          <Img
                            src="/images/img_settings_default_white.svg"
                            alt="settings"
                            className="h-[40px] sm:h-[40px] object-cover" 
                          />
                        </div>
                      </div>
                    
                    {/* Utilisation de PrimeReact DataTable */}
                    <DataTable 
                      value={filteredData} 
                      paginator 
                      loading={loading}
                      rows={10} 
                      className="p-datatable-striped"
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    >
                      <Column 
                        field="email" 
                        header="Email" 
                        className="overflow-hidden" 
                        body={(rowData) => (
                          <Link to={`/admin/statistique/user/${rowData.userId}?film=${id}`}
                            style={{ backgroundColor: 'transparent', border: 'none', color: '#fff', cursor: 'pointer' }}
                          >
                            {rowData.email}
                          </Link>
                        )}
                        sortable
                      />
                      <Column field="createdAt" header="Créé le" sortable className="overflow-hidden" />
                      <Column field="sequenceCount" header="Nombre de clips" className="overflow-hidden"  body={(rowData) => `${rowData.sequenceCount}`} sortable className="text-center" />
                      <Column field="totalDuration" header="Durée des clips (s)" className="overflow-hidden"  body={(rowData) => `${rowData.totalDuration}`} sortable className="text-center" />
                    </DataTable>
                  </div>
                   {/* Bouton pour exporter le CSV */}
                   <div className="mb-3 text-center">
                      <button 
                        onClick={exportCSVUser} 
                        class="btn-clip"
                        style={{ padding: "10px 20px", color: "white", borderRadius: "5px", cursor: "pointer" }}
                      >
                        Télécharger CSV
                      </button>
                    </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
