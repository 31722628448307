import React from "react";
import { useRoutes } from "react-router-dom";
import Browse from "pages/Browse";
import Film from "pages/Film";
import Editermonextrait from "pages/Editermonextrait";
import Mesinfospersonnelles from "pages/Mesinfospersonnelles";
import Crationcompteviarseaux from "pages/Crationcompteviarseaux";
import Connexion from "pages/Connexion";
import Register from "pages/Register";
import MesssequencessixPage from "pages/Messsequencessix";
import MescommandesonePage from "pages/Mescommandesone";
import WalletPage from "pages/Wallet";
import PrfrencesdePage from "pages/Prfrencesde";
import MentionslPage from "pages/Mentionsl";
import TlchargementPage from "pages/Tlchargement";
import PrivacyPolicy from "pages/PrivacyPolicy";
import ConditionsGeneralesUtilisation from "pages/ConditionsGeneralesUtilisation";
import ConditionsGeneralesUtilisationRecut from "pages/ConditionsGeneralesUtilisationRecut";
import AdminStatistiquePage from "pages/AdminStatistique";
import AdminStatistiquePageFilm from "pages/AdminStatistique/film";
import AdminStatistiquePageSequence from "pages/AdminStatistique/sequence";
import NotFound from "pages/NotFound";
import PrivateRoute from "./PrivateRoute";
import PrivateAdminRoute from "./PrivateAdminRoute";
import AdminStatistiquePageUser from "pages/AdminStatistique/user";
import AdminCatalogue3Page from "pages/AdminCatalogue3";
import AdminStatistiquePageUserLogs from "pages/AdminStatistique/userlogs";

const ProjectRoutes = () => {
  const element = useRoutes([
    { path: "/", element: <Browse /> },
    // Routes Admin protégées
    { 
      path: "/admin/statistiques", 
      element: <PrivateAdminRoute><AdminStatistiquePage /></PrivateAdminRoute> 
    },
    { 
      path: "/admin/statistique/film/:id", 
      element: <PrivateAdminRoute><AdminStatistiquePageFilm /></PrivateAdminRoute> 
    },
    { 
      path: "/admin/statistique/film/:idfilm/sequence/:id", 
      element: <PrivateAdminRoute><AdminStatistiquePageSequence /></PrivateAdminRoute> 
    },
    { 
      path: "/admin/statistique/user/:id", 
      element: <PrivateAdminRoute><AdminStatistiquePageUser /></PrivateAdminRoute> 
    },
    { 
      path: "/admin/statistique/user/:id/logs", 
      element: <PrivateAdminRoute><AdminStatistiquePageUserLogs /></PrivateAdminRoute> 
    },
    
    // Routes protégées par authentification
    { 
      path: "/show/:id", 
      element: <PrivateRoute><Editermonextrait /></PrivateRoute> 
    },
    { 
      path: "/:producteur/:pseudofilm/:pseudosequence/:id", 
      element: <PrivateRoute><Editermonextrait /></PrivateRoute> 
    },
    { 
      path: "/film/:id", 
      element: <Film /> 
    },
    { 
      path: "/messsequencessix", 
      element: <PrivateRoute><MesssequencessixPage /></PrivateRoute> 
    },
    { 
      path: "/mesinfospersonnelles", 
      element: <PrivateRoute><Mesinfospersonnelles /></PrivateRoute> 
    },
    { 
      path: "/mescommandesone", 
      element: <PrivateRoute><MescommandesonePage /></PrivateRoute> 
    },
    { 
      path: "/wallet", 
      element: <PrivateRoute><WalletPage /></PrivateRoute> 
    },
    { 
      path: "/preferences", 
      element: <PrivateRoute><PrfrencesdePage /></PrivateRoute> 
    },
    { 
      path: "/mentions-legales", 
      element: <PrivateRoute><MentionslPage /></PrivateRoute> 
    },
    { 
      path: "/panier", 
      element: <PrivateRoute><TlchargementPage /></PrivateRoute> 
    },

    // Routes publiques
    { path: "/testp", element: <AdminCatalogue3Page /> },

    { path: "/conditions-generales-utilisation", element: <ConditionsGeneralesUtilisation /> },
    { path: "/conditions-generales-utilisation-recut", element: <ConditionsGeneralesUtilisationRecut /> },
    { path: "/connexion", element: <Connexion /> },
    { path: "/connexionseaux", element: <Crationcompteviarseaux /> },
    { path: "/register", element: <Register /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/404", element: <NotFound /> },

    // Route dynamique pour les films
    { path: "/:producteur/:pseudofilm", element: <Film /> },
    
    // Route par défaut pour les pages non trouvées
    { path: "*", element: <NotFound /> },
  ]);

  return element;
};

export default ProjectRoutes;
