import { Text, Heading, Img } from "./..";
import React from "react";

export default function AdminCatalogueRowonehundredfo({
  image = "/images/img_film.svg",
  count = "0",
  label = "Séquences",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex  items-start gap-[23px] p-[22px] bg-gray-900 rounded-[17px]`}>
      <Img src={image} alt="image" className="mt-3.5 h-[33px] w-[33px]" />
      <div className="mb-1.5 flex flex-1 flex-col items-start gap-4">
        <Heading size="headline_3" as="h3">
          {count}
        </Heading>
        <Text as="p">{label}</Text>
      </div>
    </div>
  );
}
