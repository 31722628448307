import { Helmet } from "react-helmet";
import { SelectBox, Img, Text, Input, Heading } from "../../components";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import React, { Suspense, useEffect, useState } from "react";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import { getUserSequences } from "services/adminServices";
import MesSequenceUser from "components/MesSequenceUser";
import Footer from "components/Footer";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

export default function AdminStatistiquePageUserLogs() {
  const { id } = useParams();
  const [sequences, setSequences] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [nbSequences, setNbSequences] = useState(0);
  const [filmId, setFilmId] = useState(null);
  const [sequenceId, setSequenceId] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setFilmId(params.get('film')?params.get('film'):null);
    setSequenceId(params.get('sequence')?params.get('sequence'):null);
    const fetchData = async () => {
      try {
        const data = await getUserSequences(id);
        setSequences(data);
        console.log(data);
        setNbSequences(sequences.length)
      } catch (err) {
        setError(err.message);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // Scroll to the top
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
      </Helmet>
      <div className="relative w-full">
        <div className="flex flex-col z-[5] gap-[27px]">
          <AdminGestionPageDistributeurNavbar />
          <div className="ml-[54px] flex w-[100%] items-start gap-2.5 md:ml-0 md:w-full md:p-5">
            <Sidebar />
            <div className="">
                { 
                sequenceId?<Link to={"/admin/statistique/film/"+filmId+"/sequence/"+sequenceId} className="mb-[20px]"><Heading size="headline_6" as="h6">&lt; Statistiques Sequence</Heading></Link>: 
                filmId?<Link to={"/admin/statistique/film/"+filmId} className="mb-[20px]"><Heading size="headline_6" as="h6">&lt; Statistiques Film</Heading></Link>:
                <Link to={"/admin/statistiques"} className="mb-[20px]"><Heading size="headline_6" as="h6">&lt; Statistiques</Heading></Link>
                }
               
                <Suspense fallback={<div className="loader">Loading feed...</div>}>
                 
                </Suspense>
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
