import { Helmet } from "react-helmet";
import { Text, Button, Img, Heading } from "../../components";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { InstagramLogin , TikTokLogin , GoogleLogin, isLogin } from "../../services/authServices"
import Footer from "components/Footer";

export default function CrationcompteviarseauxPage() {

  const location = useLocation();
  const [toNavigate, setToNavigate] = useState(false); 

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const NavigateToSequence = params.get('sequence');
    
    if (NavigateToSequence && NavigateToSequence>0) {
      console.log('NavigateToSequence:', NavigateToSequence);
      localStorage.setItem('NavigateToSequence', NavigateToSequence);
    }

    if (token) {
      console.log('Token:', token);
      localStorage.setItem('token', token);
      setToNavigate(true);
      // Vous pouvez maintenant utiliser le token comme vous le souhaitez
    } else {
      console.log('Token not found');
    }
  }, [location]);

  if(isLogin()){
    if(localStorage.getItem('NavigateToSequence')&& localStorage.getItem('NavigateToSequence')>0){
      const sequenceId = localStorage.getItem('NavigateToSequence');
      localStorage.setItem('NavigateToSequence',null);
      return <Navigate to={"/show/" + sequenceId} />
    }else{
      return <Navigate to="/" />
    }
  }

  const handleGoogleLogin = () => {
    GoogleLogin()
  };

  const handleInstagramLogin = () => {
    InstagramLogin();
  };

  const handleTikTokLogin = () => {
    TikTokLogin()
  };

  if (toNavigate) return <Navigate to="/" />;
  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
      </Helmet>
      <div className="relative h-[100vh] w-full md:h-[100vh]">

      <div class="MuiBox-root css-16puer9"></div>
      <div class="MuiBox-root css-fredw7"></div>
      <div class="MuiBox-root css-awh48z"></div>
      <div class="MuiBox-root css-11tkosz"></div>
      <div class="MuiBox-root css-x2gc8h"></div>
      <div class="MuiBox-root css-1x7vn5a"></div>
      <div class="MuiBox-root css-1belss4"></div>



        <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max w-[25%] md:w-[90%] flex-col items-center">
          <Link to="/"><Img src="images/img_settings.svg" alt="headerlogo" className="h-[50px] w-[88px] object-contain" /></Link>
          <Heading size="headline_2" as="h1" className="mt-[60px] text-center">
            Hook Social Media
          </Heading>
          <div className="mt-[10px] mb-[20px] ">
            <div className="flex items-center justify-start mt-5">
            <span class="inline-flex items-center justify-center w-6 h-6 rounded-full bg-white text-black mr-2" style={{background:'white'}}>
            1
              </span>
              <Text as="p">Créez un Compte</Text>
            </div>
            <div className="flex items-center justify-start mt-5">
              <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-white text-black mr-2" style={{background:'white'}}>
                2
              </span>
              <Text as="p">Choisissez un Extrait</Text>
            </div>
            <div className="flex items-center justify-start mt-5">
              <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-white text-black mr-2" style={{background:'white'}}>
                3
              </span>
              <Text as="p">Téléchargez le règlement</Text>
            </div>
          </div>
         
          <div className="mt-9 flex flex-col gap-[21px] self-stretch">
          <Link to='/register' >
          <Button     
              color="default_white"
              size="6xl"
              shape="round"
             
              className="w-full gap-[30px] sm:px-5 rounded-full border border-purple-500"
            >
              Créer un compte via une adresse email
            </Button>
            </Link>
            </div>
         
          <div className="mt-9 flex flex-col gap-[21px] self-stretch">
            <Button
              onClick={handleGoogleLogin}
              color="default_white"
              size="6xl"
              shape="round"
              leftIcon={
                <Img src="images/img_social_icons_red_500.svg" alt="social icons" className="h-[34px] w-[34px]" />
              }
              className="w-full gap-[22px] sm:px-5 rounded-full border border-purple-500"
            >
              Se connecter via Google
            </Button>
         
          </div>
          <div className="mt-[40px] flex flex-wrap gap-[11px]">
            <Link to='/connexion' className="flex flex-wrap gap-[11px]">
              <Text as="p">Déjà membre ?</Text>
              <Text as="p" className="color-recut">Connexion</Text>
            </Link>
          </div>
        </div>
      </div> 
      <Footer/>
    </>
    
  );
}

