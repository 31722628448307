import axios from 'axios';
import apiClient from '../axios-interceptor';

const apiUrl = process.env.REACT_APP_API_URL+"/films";

export async function getFilms() {
  try {
    const response = await apiClient.get(apiUrl+'/');
    if(response.data){
      return response.data; 
    }
    
  } catch (error) {
    console.log('Error fetchData:', error);
    throw error;
  }
  return null;
}

export async function getFilm(id) {
  try {
    const response = await apiClient.get(apiUrl+'/'+id);
    if(response.data){
      return response.data; 
    }
    
  } catch (error) {
    console.log('Error fetchData:', error);
    throw error;
  }
  return null;
}

export async function getSequencesByFilm(id) {
  try {
    const response = await apiClient.get(apiUrl+"/"+id+'/sequences/');
    if(response.data){
      return response.data; 
    }
    
  } catch (error) {
    console.log('Error fetchData:', error);
    throw error;
  }
  return null;
}

export async function getSequencesHome(search="") {
  try {
    const response = await apiClient.get(`${apiUrl}/sequences/home`, {
      params: { search },
    });

    return response.data || null; // Renvoie response.data ou null si c'est indéfini

  } catch (error) {
    console.error('Error fetching sequences home:', error);
    throw error; // Rejeter l'erreur pour qu'elle puisse être gérée en amont
  }
}
export async function getSequencesHome2(page=1) {
  try {
    const response = await apiClient.get(`${apiUrl}/sequences/home2`, {
      params: { page },
    });

    return response.data || null; // Renvoie response.data ou null si c'est indéfini

  } catch (error) {
    console.error('Error fetching sequences home:', error);
    throw error; // Rejeter l'erreur pour qu'elle puisse être gérée en amont
  }
}



