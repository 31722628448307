import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import { BigPlayButton, ControlBar, Player, ProgressControl, VolumeMenuButton } from 'video-react';
import "video-react/dist/video-react.css";

const HLSSource = ({ src, dataTimeClipVideo, disabledClipVideo,control=true, ...props }) => {
  const calculateVideoHeight = (width) => {
    return width > 1300 ? 800 : width * 9 / 16;
  };

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth - 4);
  const [videoHeight, setVideoHeight] = useState(calculateVideoHeight(window.innerWidth));
  const [currentSource, setCurrentSource] = useState(src);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentQualityVideo, setCurrentQualityVideo] = useState("720p");
  const [oldTimeEnd, setOldTimeEnd] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth - 4;
      setWindowWidth(newWidth);
      setVideoHeight(calculateVideoHeight(newWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const { timeStart, timeEnd } = dataTimeClipVideo;
    
    if (Hls.isSupported()) {
      setCurrentTime(videoRef.current.getState().player.currentTime);
      const hls = new Hls();
      hls.loadSource(currentSource);
      hls.attachMedia(videoRef.current.video.video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        const startTime = timeStart !== -1 ? timeStart : currentTime;
        videoRef.current.video.video.currentTime = startTime;
        if (!disabledClipVideo) {
          videoRef.current.video.video.play().catch(error => {
            console.log('Play request interrupted:', error);
          });
        }
      });
    } else if (videoRef.current.video.video.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.video.video.src = currentSource;
      videoRef.current.video.video.addEventListener('loadedmetadata', () => {
        if (!disabledClipVideo) {
          videoRef.current.video.video.play().catch(error => {
            console.log('Play request interrupted:', error);
          });
        }
      });
    }

    const intervalId = setInterval(() => {
      if (videoRef.current) {
        const canvas = canvasRef.current;
        if (disabledClipVideo) {
            if(oldTimeEnd != timeEnd){
              videoRef.current.video.video.currentTime = timeEnd;
              videoRef.current.video.video.pause();
              const context = canvas.getContext('2d');
              context.drawImage(videoRef.current.video.video, 0, 0, canvas.width, canvas.height);
            }else{
              videoRef.current.video.video.pause();
              const context = canvas.getContext('2d');
              context.drawImage(videoRef.current.video.video, 0, 0, canvas.width, canvas.height);
            }
        }else{
          setOldTimeEnd(timeEnd);
        }
      }
    },1);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentSource, dataTimeClipVideo, disabledClipVideo,canvasRef]);

  const handleQualityChange =(event) => {
    setCurrentSource(src.replace("720p.m3u8", `${event.target.value}.m3u8`));
    setCurrentQualityVideo(event.target.value);
  };

  const handleTimeUpdate = () => {
    const { timeStart, timeEnd } = dataTimeClipVideo;
    if (videoRef.current) {
      if (timeEnd==timeStart && timeStart==0) {
        videoRef.current.video.video.pause();
        return false;
      }
      try {
        const currentTime = videoRef.current.getState().player.currentTime;
        setCurrentTime(currentTime);
        if (!isNaN(timeEnd) && timeEnd !== -1 && currentTime >= timeEnd) {
          videoRef.current.video.video.currentTime = timeStart;
        }
        
      } catch (error) {
        console.log('Error updating current time:', error);
      }
    }
  };

  return (
    <div style={{ "position" : "relative" }}>
      <Player
        ref={videoRef}
        fluid={false}
        height={videoHeight}
        onTimeUpdate={handleTimeUpdate}
        width="100%"
        playsInline
        webkit-playsinline="true"
        {...props}>
        <source src={currentSource} />
        <BigPlayButton position="center" className="PlayerBigPlayButton" />
        <ControlBar autoHide={false} disableCompletely={control} className="PlayerControlBar" >
          <VolumeMenuButton vertical />
          <ProgressControl />
          <select order={9} className="quality-select" onChange={handleQualityChange} value={currentQualityVideo}>
            <option value="144p">144p</option>
            <option value="240p">240p</option>
            <option value="480p">480p</option>
            <option value="720p">720p</option>
            <option value="1080p">1080p</option>
          </select>
        </ControlBar>
      </Player>
      <canvas ref={canvasRef} className='canvasPlayerdisabledClipVideo' style={{ display: disabledClipVideo ? 'block' : 'none',height:parseInt(videoHeight)+"px" }} />
     {/* <div className='mb-[150px]'>
      <p style={{ "color": "#fff" }}>timeStart : {dataTimeClipVideo.timeStart}</p>
      <p style={{ "color": "#fff" }}>timeEnd : {dataTimeClipVideo.timeEnd}</p>
      <p style={{ "color": "#fff" }}>oldtimeEnd : {oldTimeEnd}</p>
      <p style={{ "color": "#fff" }}>currentTime : {currentTime}</p>
      <p style={{ "color": "#fff" }}>disabledClipVideo: {disabledClipVideo ? "True" : "False"}</p>
     </div> */}

    </div>
  );
};

export default HLSSource;
