import { Helmet } from "react-helmet";
import { SelectBox, Img, Text, Input, Heading } from "../../components";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import React, { Suspense, useEffect, useState } from "react";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import { getUserSequences } from "services/adminServices";
import MesSequenceUser from "components/MesSequenceUser";
import Footer from "components/Footer";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const formatDate = (date) => {
  if (!date) {
    return 'N/A'; // Handle null or undefined dates
  }
  
  const d = new Date(date);

  // Check if the date is valid
  if (isNaN(d.getTime())) {
    return 'Invalid date'; // Return a fallback value instead of throwing an error
  }

  // Extract date components
  const year = String(d.getFullYear());
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(d.getDate()).padStart(2, '0');
  const hours = String(d.getHours()).padStart(2, '0');
  const minutes = String(d.getMinutes()).padStart(2, '0');
  const seconds = String(d.getSeconds()).padStart(2, '0');

  // Return formatted date as 'YY/MM/DD HH:mm:ss'
  return `${year}/${month}/${day} à ${hours}:${minutes}:${seconds}`;
};


const getCurrentDateTime = () => {
  const now = new Date();

  // Format the date and time components
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  // Combine to get the final formatted string
  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};


export default function AdminStatistiquePageUser() {
  const { id } = useParams();
  const [sequences, setSequences] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataSequences, setDataSequences] = useState([]);
  const appUrl = process.env.REACT_APP_URL;
  const [nbSequences, setNbSequences] = useState(0);
  const [filmId, setFilmId] = useState(null);
  const [sequenceId, setSequenceId] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setFilmId(params.get('film')?params.get('film'):null);
    setSequenceId(params.get('sequence')?params.get('sequence'):null);
    const fetchData = async () => {
      try {
        const data = await getUserSequences(id);
        setSequences(data);
        const tampdata = [];
        data.map((sequence, index) => {
          let datapuched = {
            filmId: sequence.Sequence.film.id,
            filmImg: `${appUrl}public/films/${sequence.Sequence.film.id}-affiche.jpg`,
            filmName: sequence.Sequence.film.title,
            sequenceId: sequence.Sequence.id,
            sequenceName: sequence.Sequence.title,
            sequenceImg: `${appUrl}${sequence.Sequence.urlimage}`,
            durationClip: sequence.durationSequence,
            timeEndClip: sequence.timeEnd,
            timeStartClip: sequence.timeStart,
            createdAt: formatDate(sequence.createdAt), // Ensure createdAt is passed through formatDate
          }
          // Si sequenceId et filmId sont vides, ajouter toutes les séquences
          if (!sequenceId && !filmId) {
            tampdata.push(datapuched);
          }
           // Si filmId n'est pas vide, ajouter seulement les séquences avec cet ID de film
           else if (filmId && filmId === datapuched.filmId) {
            tampdata.push(datapuched);
          }
          // Si sequenceId n'est pas vide, ajouter seulement les séquences avec cet ID
          else if (sequenceId && sequenceId === datapuched.sequenceId) {
            tampdata.push(datapuched);
          }
         
        });
        setDataSequences(tampdata);
        console.log(tampdata);
        setNbSequences(sequences.length)
      } catch (err) {
        setError(err.message);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // Scroll to the top
    window.scrollTo(0, 0);
  }, []);

  // Fonction pour exporter les données sous forme de CSV
  const exportCSVUser = () => {
    const csvRows = [];
    const headers = ['Chalenge', 'Sequence', 'Durée de clip', 'Start clip', 'End clips', 'Created At'];
    csvRows.push(headers.join(';')); // Ajouter les en-têtes
  
    // Ajouter les lignes des données
    dataSequences.forEach((clip) => {
      const row = [
        clip.filmName,
        clip.sequenceName,
        clip.durationClip,
        clip.timeEndClip,
        clip.timeStartClip,
        clip.createdAt
      ];
      csvRows.push(row.join(';'));
    });
  
    // Créer un Blob à partir des données CSV
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    const current_datetime = getCurrentDateTime();
    a.setAttribute('download', 'U-users_statistiques-' + current_datetime + '.csv');
    a.click();
  };
  
  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
      </Helmet>
      <div className="relative w-full">
        <div className="flex flex-col z-[5] gap-[27px]">
          <AdminGestionPageDistributeurNavbar />
          <div className="ml-[54px] flex w-[80%] items-start gap-2.5 md:ml-0 md:w-full md:p-5">
            <Sidebar />
            <div className="mt-12 flex w-[100%] flex-col gap-[60px] sm:gap-[30px]">
              { 
                sequenceId?<Link to={"/admin/statistique/film/"+filmId+"/sequence/"+sequenceId} className="mb-[20px]"><Heading size="headline_6" as="h6">&lt; Statistiques Sequence</Heading></Link>: 
                filmId?<Link to={"/admin/statistique/film/"+filmId} className="mb-[20px]"><Heading size="headline_6" as="h6">&lt; Statistiques Film</Heading></Link>:
                <Link to={"/admin/statistiques"} className="mb-[20px]"><Heading size="headline_6" as="h6">&lt; Statistiques</Heading></Link>
              }
              <div className="flex w-[100%] flex-col items-end gap-[55px] md:w-full md:p-5 sm:gap-[27px]">
                <div className="flex flex-col gap-[37px] self-stretch">
                  <div className="container-xs">
                  
                    {/* Tableau des utilisateurs déjà existant */}
                    <div className="self-stretch mb-5">
                      {/* Utilisation de PrimeReact DataTable */}
                      <DataTable 
                        value={dataSequences} 
                        paginator 
                        loading={loading}
                        filters={dataSequences} 
                        filterDisplay="row"
                        rows={10} 
                        sortField="sequenceCount" // Champ à trier par défaut
                        sortOrder={-1} // Ordre décroissant
                        className="p-datatable-striped"
                        globalFilterFields={['filmName', 'sequenceName', 'durationClip']}
                        style={{ backgroundColor: 'transparent', color: '#fff' }}
                      >
                        <Column 
                          className="w-[100px]" 
                          field="filmName" 
                          header="Chalenge" 
                          body={(rowData) => (
                            <Img src={`${rowData.filmImg}`}
                              alt={`${rowData.filmName}`}
                              className="h-[50px] rounded-[4px] object-contain"
                            />
                          )}  
                          sortable />
                        <Column
                          className="w-[100px]" 
                          field="sequenceName" 
                          header="Sequence" 
                          body={(rowData) => (
                            <Img src={`${rowData.sequenceImg}`}
                              alt={`${rowData.sequenceName}`}
                              className="w-[100px] h-[50px] rounded-[4px] object-cover"
                            />
                          )}
                          sortable
                        />
                        <Column className="text-center"  field="durationClip" header="Durée de clip" body={(rowData) => `${rowData.durationClip}`} sortable />
                        <Column className="text-center"  field="timeStartClip" header="Start clip" body={(rowData) => `${rowData.timeStartClip}`} sortable />
                        <Column className="text-center"  field="timeEndClip" header="End clips" body={(rowData) => `${rowData.timeEndClip}`} sortable />
                        <Column className="text-center"  field="createdAt" header="created At" body={(rowData) => `${rowData.createdAt}`} sortable />
                        
                      </DataTable>
                    </div>
                     {/* Bouton pour exporter le CSV */}
                     <div className="mb-3 text-center">
                      <button 
                        onClick={exportCSVUser} 
                        class="btn-clip"
                        style={{ padding: "10px 20px", color: "white", borderRadius: "5px", cursor: "pointer" }}
                      >
                        Télécharger CSV
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
    // <>
    //   <Helmet>
    //     <title>ReCut</title>
    //     <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
    //   </Helmet>
    //   <div className="relative w-full">
    //     <div className="flex flex-col z-[5] gap-[27px]">
    //       <AdminGestionPageDistributeurNavbar />
    //       <div className="ml-[54px] flex w-[100%] items-start gap-2.5 md:ml-0 md:w-full md:p-5">
    //         <Sidebar />
    //         <div className="">
    //             { 
    //             sequenceId?<Link to={"/admin/statistique/film/"+filmId+"/sequence/"+sequenceId} className="mb-[20px]"><Heading size="headline_6" as="h6">&lt; Statistiques Sequence</Heading></Link>: 
    //             filmId?<Link to={"/admin/statistique/film/"+filmId} className="mb-[20px]"><Heading size="headline_6" as="h6">&lt; Statistiques Film</Heading></Link>:
    //             <Link to={"/admin/statistiques"} className="mb-[20px]"><Heading size="headline_6" as="h6">&lt; Statistiques</Heading></Link>
    //             }
               
    //             <Suspense fallback={<div className="loader">Loading feed...</div>}>
    //              {/* Utilisation de PrimeReact DataTable */}
         
    //                 <DataTable 
    //                     value={dataSequences} 
    //                     paginator 
    //                     rows={10} 
    //                     sortField="sequenceCount" // Champ à trier par défaut
    //                     sortOrder={-1} // Ordre décroissant
    //                     className="p-datatable-striped"
    //                     style={{ backgroundColor: 'transparent', color: '#fff' }}
    //                   >
    //                     <Column 
    //                       field="filmName" 
    //                       header="Chalenge" 
    //                       body={(rowData) => (
    //                         <Img src={`${rowData.filmImg}`}
    //                           alt={`${rowData.filmName}`}
    //                           className="w-[30px] rounded-[4px] object-contain"
    //                         />
    //                       )}  
    //                       sortable />
    //                     <Column 
    //                       field="sequenceName" 
    //                       header="Sequence" 
    //                       body={(rowData) => (
    //                         <Img src={`${rowData.sequenceImg}`}
    //                           alt={`${rowData.sequenceName}`}
    //                           className="w-[70px] rounded-[4px] object-contain"
    //                         />
    //                       )}
    //                       sortable
    //                     />
    //                     <Column field="durationClip" header="Durée de clip" body={(rowData) => `${rowData.durationClip}`} sortable />
    //                     <Column field="timeStartClip" header="Start clip" body={(rowData) => `${rowData.timeStartClip}`} sortable />
    //                     <Column field="timeEndClip" header="End clips" body={(rowData) => `${rowData.timeEndClip}`} sortable />
    //                     <Column field="createdAt" header="created At" body={(rowData) => `${rowData.createdAt}`} sortable />
                        
    //                   </DataTable>
    //               {/* {sequences.map((sequence, index) => {
    //                   if(filmId){
    //                     console.log(sequence.Sequence);
    //                     if(sequence.Sequence.filmId == filmId){
    //                       if(sequenceId){
    //                         if(sequence.Sequence.id == sequenceId){
    //                           return <MesSequenceUser {...sequence} sequencedata={sequence} key={"tlchargement" + index} download={false}/>;
    //                         }
    //                       }else{
    //                         return <MesSequenceUser {...sequence} sequencedata={sequence} key={"tlchargement" + index} download={false}/>;
    //                       }
    //                     }
    //                   }else{
    //                     return <MesSequenceUser {...sequence} sequencedata={sequence} key={"tlchargement" + index} download={false}/>;
    //                   }
    //                 }
    //               )} */}
    //             </Suspense>
    //           </div>
    //       </div>
    //     </div>
    //   </div>
    //   <Footer />
    // </>
  );
}
