import { Img, Text, Heading } from "./..";
import React from "react";

export default function AdminCatalogueRowlaudition({
  timetext = "02:30",
  auditiontext = "L’audition",
  numbertext = "126",
  movietitle = "Les Choristes (2004)",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex items-center`}>
      <div className="flex flex-1 flex-col items-start gap-3 sm:self-stretch">
        <div className="relative h-[161px]">
          <Img
            src="/images/img_rectangle_92_362x292.png"
            alt="image"
            className="h-[161px] w-full rounded-[7px] object-cover"
          />
          <div className="absolute right-[8.00px] top-[8.00px] m-auto flex w-[28%] justify-end gap-[3px]">
            <div className="w-full rounded bg-black-900_bf p-[3px]">
              <div className="flex">
                <Img src="/images/img_star_10_122.svg" alt="image" className="h-[11px] w-[11px]" />
                <Img src="/images/img_star_11_95.svg" alt="image" className="h-[11px] w-[11px]" />
              </div>
            </div>
            <Text size="caption" as="p" className="flex items-center justify-center rounded bg-black-900_bf px-1 py-px">
              {timetext}
            </Text>
          </div>
        </div>
        <div className="mb-3.5 flex w-[77%] flex-col items-start gap-3">
          <Heading as="p">{auditiontext}</Heading>
          <div className="flex flex-wrap items-center self-stretch">
            <Img src="/images/img_arrow_down_circle.svg" alt="image" className="h-[16px] w-[16px]" />
            <Text as="p" className="ml-[5px] !text-gray-500">
              {numbertext}
            </Text>
            <Img src="/images/img_film_gray_500.svg" alt="image" className="ml-3.5 h-[16px] w-[16px]" />
            <Text as="p" className="ml-1.5 !text-gray-500">
              {movietitle}
            </Text>
          </div>
        </div>
      </div>
      <div className="relative z-[2] ml-[-20px] flex w-[35%] flex-col items-end justify-end rounded-[19px] bg-gradient2 p-[18px]">
        <Img src="/images/img_arrowdown.svg" alt="arrowright" className="mb-[100px] mt-[113px] h-[28px] w-[28px]" />
      </div>
    </div>
  );
}
