import { Link } from "react-router-dom";
import { Text, Heading, Button, Img } from "./..";
import React, { useEffect, useState } from "react";
import { downloadSequences } from "services/sequencesServices";
import { isLogin } from "services/authServices";
import axios from 'axios';

export default function FilmCardSequence({
  idsequence = "0",
  filmId= "0",
  urlimage = "",
  timesequence = "00:00",
  scneomondain = "",
  leschoristes = "",
  afficherTelechargement= true,
  producteur="pathe",
  pseudofilm="",
  ...props
}) {
  const appUrl = process.env.REACT_APP_URL;
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    if(pseudofilm=="") pseudofilm="/film/" + filmId;
    // document.addEventListener('mousedown', handleMouseLeave);
    // return () => {
    //   document.removeEventListener('mousedown', handleMouseLeave);
    // };
  }, []);

  const downloadVideo = async (idSequence, format) => {
    try {
        setIsLoading(true);
        const response = await downloadSequences(idSequence, format);
        setIsLoading(false);
        const blob = new Blob([response.data], { type: 'video/mp4' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${scneomondain}_${format}.mp4`;
        link.click();
    } catch (error) {
        setIsLoading(false);
        console.error('Error downloading the video', error);
    }
  };

  const handleButtonDownloadClick = (format) => {
    downloadVideo(idsequence, format);
    setShowPopup(false);
  };

  const handleButtonClick = () => {
    setShowPopup(!showPopup);
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
    // setTimeout(() => {
    //   setShowPopup(false);
    // }, 100);
  };

  
  const convertToSlug = (title) => {
    return title
      .toLowerCase()
      .trim()
      .replace(/[\s\W-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  };

  return (
    <div {...props} className={`${props.className} flex flex-col gap-[13px] ml-[10px] mr-[10px]`}>
      {isLoading && <div className="loading-recut">
        <Img
                src="/images/loading.gif"
                alt="image"
                className="h-[200px] object-cover cursor-pointer"
        />
        </div>}
      <div className="relative h-[200px] self-stretch container-blockButtonDownloadClip">
        {afficherTelechargement &&
        <>
        <Link to={isLogin()?"/" +producteur+"/"+ pseudofilm+"/"+convertToSlug(scneomondain)+"/"+ idsequence:"/connexionseaux?sequence="+ idsequence} className="absolute w-[100%] h-[100%] blockButtonDownloadClip flex justify-center items-center">
          <div className="flex">
            {/* <Img
              src="/images/background/ButtonDownload.svg"
              alt="image"
              className="h-[50px] w-[50px] rounded-[7px] object-cover cursor-pointer"
              onClick={handleButtonClick}
            /> */}
            <Link to={isLogin()?"/" +producteur+"/"+ pseudofilm+"/"+convertToSlug(scneomondain)+"/"+ idsequence:"/connexionseaux?sequence="+ idsequence}>
              <Img
                src="/images/background/ButtonClip.svg"
                alt="image"
                className="h-[45px] w-[45px] text-xs rounded-[7px] object-cover cursor-pointer"
              />
            </Link>
          </div>
        </Link>
        <Img
          src={appUrl + urlimage}
          alt="image"
          className="h-[200px] w-full rounded-[15px] object-cover"
        />
        </>
        }

        {!afficherTelechargement && 
        <Link to={isLogin()?"/" +producteur+"/"+ pseudofilm+"/"+convertToSlug(scneomondain)+"/"+ idsequence:"/connexionseaux?sequence="+ idsequence}>
          <Img
            src={appUrl + urlimage}
            alt="image"
            className="h-[200px] w-full rounded-[15px] object-cover"
          />
        </Link> 
        }
        <div className="absolute right-[15px] top-[15px] m-auto flex w-[30%] justify-end gap-[5px]">
          <Text as="p" className="flex items-center justify-center rounded bg-black-900_bf px-[10px] py-[5px]">
            {timesequence}
          </Text>
        </div>
        {showPopup && (
          <div 
            className="absolute top-[-50px] w-[300px] left-0 mt-12 ml-12 p-4 bg-white shadow-lg rounded-lg z-50 bg-black-900"
            onMouseLeave={handleMouseLeave}
          >
            <Text as="h1">Téléchargement</Text>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("vertical")}>
              <Text as="p">Vertical</Text>
              <Img
                src="/images/icons-social-tiy.svg"
                alt="image"
                className="h-[20px] rounded-[7px] object-cover"
              />
              <div>
                <Img
                  src="/images/download.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("horizontal")}>
              <Text as="p">Horizontal</Text>
              <Img
                src="/images/icon-youtube.svg"
                alt="image"
                className="h-[20px] rounded-[7px] object-cover"
              />
              <div>
                <Img
                  src="/images/download.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("square")}>
              <Text as="p">Carré</Text>
              <Img
                src="/images/icons-social.svg"
                alt="image"
                className="h-[20px] rounded-[7px] object-cover"
              />
              <div>
                <Img
                  src="/images/download.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("original")}>
              <Text as="p">Original</Text>
              <div>
                <Img
                  src="/images/download.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex w-[73%]">
        <div className="flex w-full items-start pt-[0px] gap-[15px]">
          <Img src={"/images/logo-"+producteur+"-sequences.png"} className="w-[30px] !rounded-[14px]"></Img>
          <div className="flex flex-1 flex-col items-start gap-[9px]">
            <Link to={isLogin()?"/" +producteur+"/"+ pseudofilm+"/"+convertToSlug(scneomondain)+"/"+ idsequence:"/connexionseaux?sequence="+ idsequence}>
              <Heading size="subtitle_1" as="h6">
                {scneomondain}
              </Heading>
            </Link>
            <Link to={"/" +producteur+"/"+ pseudofilm}>
              <Text as="p" className="!text-gray-500">
                {leschoristes}
              </Text>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
