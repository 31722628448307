import { Helmet } from "react-helmet";
import { Text, Input, Img, Heading, Button } from "../../components";
import React, { useState } from "react";
import { Link, Navigate } from 'react-router-dom';
import { isLogin, login } from '../../services/authServices';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Footer from "components/Footer";

const schema = yup.object().shape({
  email: yup.string().email('Email invalide').required('Email est obligatoire'),
  password: yup.string().min(6, 'Le mot de passe doit comporter au moins 6 caractères').required('Mot de passe est obligatoire'),
});

export default function ConnexionPage() {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const [showErrors, setShowErrors] = useState(""); 
  const [showPassword, setShowPassword] = useState(false); 
  const [toNavigate, setToNavigate] = useState(false); 
  
  if(isLogin()){
    if(localStorage.getItem('NavigateToSequence')&& localStorage.getItem('NavigateToSequence')>0){
      const sequenceId = localStorage.getItem('NavigateToSequence');
      localStorage.setItem('NavigateToSequence',null);
      return <Navigate to={"/show/" + sequenceId} />
    }else{
      return <Navigate to="/" />
    }
  }
  const onSubmit = async (data) => {
    try {
      const success = await login(data.email, data.password);
      if (success) {
        setToNavigate(true); // Exécuter seulement si le login a réussi
      } else {
        setShowErrors(success.response.data);
    
      }
    } catch (error) {
      console.error('Login error', error);
      setShowErrors(error.response.data);
 
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  if (toNavigate) return <Navigate to="/" />;
  
  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
      </Helmet>
      <div className="relative h-[100vh] w-full md:h-[100vh]">
        <div >
          <div className="container-xs md:p-5 md:px-5">

            <div className="MuiBox-root css-16puer9"></div>
            <div className="MuiBox-root css-fredw7"></div>
            <div className="MuiBox-root css-awh48z"></div>
            <div className="MuiBox-root css-11tkosz"></div>
            <div className="MuiBox-root css-x2gc8h"></div>
            <div className="MuiBox-root css-1x7vn5a"></div>
            <div className="MuiBox-root css-1belss4"></div>
            <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max w-[20%] md:w-[90%] flex-col items-center">
              <div className="flex flex-col items-center">
                <Link to="/"><Img src="images/img_settings.svg" alt="headerlogo" className="h-[50px] w-[88px] md:mt-[50px] object-contain" /></Link> 
                <Heading size="headline_2" as="h1" className="mt-[10px] md:mt-[100px] w-[100%] text-center leading-[60px]">
                  Heureux de vous revoir !
                </Heading>
                <Text as="p" className="mt-[10px] w-full text-center leading-6">
                  En créant un compte vous rejoignez une communauté de 156 923 passionnés
                </Text>
                <Link to='/register' >
                  <Text as="p" className="mt-14 color-recut">
                    Créer un compte via une adresse email
                  </Text>
                </Link>
                <div className="mt-6 self-stretch">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="relative z-[1] py-6 sm:py-5">
                      <Input
                        shape="round"
                        type="email"
                        name="email"
                        placeholder="Email"
                        {...register('email')}
                        className="mt-2 border border-solid border-white-dark_night-300 !text-white-dark_night-500 sm:pr-5"
                      />
                      {errors.email && <Text as="p" className="text-red-600">{errors.email.message}</Text>}
                    </div>
                    <div className="relative mt-[-30px] flex flex-col items-start gap-[11px] pt-8 sm:pt-5">
                      <Input
                        shape="round"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        {...register('password')}
                        suffix={<Img src="images/img_eye.svg" alt="eye" className="h-[24px] w-[24px]" onClick={togglePasswordVisibility} />}
                        className="gap-[35px] self-stretch border border-solid border-white-dark_night-300 !text-white-dark_night-500"
                      />
                      {errors.password && <Text as="p" className="text-red-600">{errors.password.message}</Text>}
                    </div>
                    <div className="relative z-[1] py-6 sm:py-5">
                    {showErrors!="" && <Text as="p" className="text-red-600">{showErrors}</Text>}
                      <Button
                        type="submit"
                        color="deep_orange_600"
                        size="6xl"
                        shape="round"
                        className="w-full gap-[22px] border border-solid border-default-white sm:px-5"
                      >
                        Continuer
                      </Button>
                    </div>
                  </form>
                </div>
                <div className="mt-[10px] flex flex-wrap gap-[11px]">
                  <Link to='/connexionseaux' className="flex flex-wrap gap-[11px]">
                    <Text as="p">Déjà membre ?</Text>
                    <Text as="p" className="color-recut">Connexion</Text>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}
