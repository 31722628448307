import { Link, Navigate, useLocation } from "react-router-dom";
import { Img } from "./..";
import React, { useEffect, useState } from "react";
import MenuClient from "components/MenuClient";
import { isLogin, isLoginAvecRedirection } from "services/authServices";

export default function AdminGestionPageDistributeurNavbar({producteur="recut", ...props }) {
  const location = useLocation();
  const currentUrl = location.pathname;
  const [afficherMenuClient, setAfficherMenuClient] = useState(true);
  useEffect(() => {
    const favicon = document.getElementById('favicon');
    // if(producteur=="pathe"){
    //   favicon.href = '/favicon.ico';
    // }
    // if(producteur=="recut"){
    //   favicon.href = '/favicon-recut.ico';
    // }
    
    setAfficherMenuClient(isLogin())
    // if(currentUrl == "/privacy-policy"){
      
    // }else{
    //   isLoginAvecRedirection();
    // }
  });
  return (
    <div {...props} className={`${props.className} flex self-stretch justify-center items-end py-[15px] md:py-[5px] md:px-[15px]`}>
      <div className="container-xs mt-[17px] flex items-center justify-between gap-5 md:p-1 md:mt-[5px]">
        <Link to="/"> <Img src={"/images/logo-recut.png"} alt="headerlogo" className="h-[60px] object-contain" /></Link>
        <div className="flex w-[50px] items-center justify-between gap-5">
        {/* <Link to="/panier"><Img src="/images/img_cart.svg" alt="cart" className="h-[35px] w-[35px]" /></Link> */}
        {/* <Img src="/images/img_search.svg" alt="search" className="h-[36px] w-[36px]" /> */}
        <MenuClient afficherMenuClient={afficherMenuClient} />
        </div>
      </div>
    </div>
  );
}
