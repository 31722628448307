import { Helmet } from "react-helmet";
import { Text, Img, Button, Heading } from "../../components";
import AdminCatalogueCardsquence1 from "../../components/AdminCatalogueCardsquence1";
import AdminCatalogueRowlaudition from "../../components/AdminCatalogueRowlaudition";
import AdminCatalogueRowonehundredfo from "../../components/AdminCatalogueRowonehundredfo";
import { ReactTable } from "../../components/ReactTable";
import Sidebar4 from "../../components/Sidebar4";
import { createColumnHelper } from "@tanstack/react-table";
import React, { Suspense } from "react";

const data = [
  {},
  { image: "images/img_light_bulb.svg", count: "18k", label: "Téléchargements" },
  { image: "images/img_cart.svg", count: "1 298 k", label: "Chiffre d’affaire" },
  { image: "images/img_cart.svg", count: "1 298 k", label: "Autre KPI" },
];
const tableData = [
  {
    utilisateur: "Zinedine Zidane",
    rowdatedachat: "08/05/2024",
    produit: "Sequence 123",
    rowdroits: "Partage",
    rowexpirationda: "2 jours",
  },
  {
    utilisateur: "Zinedine Zidane",
    rowdatedachat: "08/05/2024",
    produit: "Sequence 123",
    rowdroits: "Transformation",
    rowexpirationda: "3 jours",
  },
  {
    utilisateur: "Zinedine Zidane",
    rowdatedachat: "08/05/2024",
    produit: "Sequence 123",
    rowdroits: "Partage",
    rowexpirationda: "9 jours",
  },
];

export default function AdminCatalogue3Page() {
  const tableColumns = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper();
    return [
      tableColumnHelper.accessor("utilisateur", {
        cell: (info) => (
          <div className="flex flex-1 items-start justify-center gap-[18px] md:self-stretch md:p-5">
            <Img
              src="images/img_ellipse_22.png"
              alt="image"
              className="h-[27px] w-[27px] rounded-[13px] object-cover"
            />
            <Text as="p" className="!text-gray-500">
              {info?.getValue?.()}
            </Text>
          </div>
        ),
        header: (info) => (
          <Text as="p" className="px-px py-[3px]">
            Utilisateur
          </Text>
        ),
        meta: { width: "219px" },
      }),
      tableColumnHelper.accessor("rowdatedachat", {
        cell: (info) => (
          <Text as="p" className="!text-gray-500 md:p-5">
            {info?.getValue?.()}
          </Text>
        ),
        header: (info) => (
          <div
            className="flex cursor-pointer items-center gap-5"
            onClick={info?.header?.column?.getToggleSortingHandler()}
          >
            <Text as="p">Date d’achat</Text>
            <Img src="images/img_arrowdown.svg" alt="arrowdown" className="h-[16px] w-[16px] self-end" />
          </div>
        ),
        meta: { width: "155px" },
      }),
      tableColumnHelper.accessor("produit", {
        cell: (info) => (
          <Text as="p" className="!text-gray-500 md:p-5">
            {info?.getValue?.()}
          </Text>
        ),
        header: (info) => (
          <Text as="p" className="py-[3px]">
            Produit
          </Text>
        ),
        meta: { width: "170px" },
      }),
      tableColumnHelper.accessor("rowdroits", {
        cell: (info) => (
          <Text as="p" className="!text-gray-500 md:p-5">
            {info?.getValue?.()}
          </Text>
        ),
        header: (info) => (
          <div
            className="flex flex-1 cursor-pointer items-center gap-[17px] md:self-stretch"
            onClick={info?.header?.column?.getToggleSortingHandler()}
          >
            <Text as="p">Droits</Text>
            <Img src="images/img_arrowdown.svg" alt="arrowdown" className="h-[16px] w-[16px] self-end" />
          </div>
        ),
        meta: { width: "225px" },
      }),
      tableColumnHelper.accessor("rowexpirationda", {
        cell: (info) => (
          <Text as="p" className="!text-red-a100 md:p-5">
            {info?.getValue?.()}
          </Text>
        ),
        header: (info) => (
          <div
            className="flex cursor-pointer items-center gap-2"
            onClick={info?.header?.column?.getToggleSortingHandler()}
          >
            <Text as="p">Expiration dans</Text>
            <Button color="gray_900" size="xs" shape="circle" className="w-[22px] !rounded-[11px]">
              <Img src="images/img_arrowdown.svg" />
            </Button>
          </div>
        ),
        meta: { width: "178px" },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
      </Helmet>
      <div className="w-full bg-black-900 pb-[51px] md:pb-5">
        <div className="flex items-start gap-[49px] overflow-auto">
          <Sidebar4 />
          <div className="mt-12 flex w-[86%] flex-col gap-[60px] sm:gap-[30px]">
            <div className="flex w-[82%] flex-col items-end gap-[55px] md:w-full md:p-5 sm:gap-[27px]">
              <div className="mr-[17px] flex w-[92%] items-start gap-[27px] md:mr-0 md:w-full md:flex-col">
                <div className="relative mt-7 h-[220px] flex-1 md:w-full md:flex-none md:self-stretch">
                  <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max w-[95%] flex-col items-center gap-[18px] rounded-[15px] border-2 border-dashed border-gray-500 p-[31px] sm:p-5">
                    <Img src="images/img_cloud_upload.svg" alt="cloudupload" className="h-[83px] w-[83px]" />
                    <div className="mb-[13px] flex w-[44%] flex-col items-end gap-2 md:w-full">
                      <div className="flex flex-wrap items-center gap-[5px] self-stretch">
                        <Text size="texts" as="p" className="!font-gtamerica underline">
                          Cliquez pour uploader
                        </Text>
                        <Text as="p" className="self-start">
                          ou déposer le fichier ici
                        </Text>
                      </div>
                      <Text as="p" className="mr-[17px] !text-gray-500 md:mr-0">
                        Formats supportés : MP4, GIF, JPG, MP3
                      </Text>
                    </div>
                  </div>
                  <Text
                    as="p"
                    className="absolute bottom-0 right-[0.00px] top-0 my-auto h-max rounded-[14px] bg-deep_purple-a200 py-1.5 pl-4 pr-[31px] sm:pr-5"
                  >
                    Sequence_1.mp4
                  </Text>
                </div>
                <Img src="images/img_rewind.svg" alt="rewind" className="h-[29px] w-[29px] md:w-full" />
              </div>
              <div className="flex flex-col gap-[37px] self-stretch">
                <div className="flex w-[97%] gap-5 md:w-full md:flex-col">
                  <Suspense fallback={<div className="loader">Loading feed...</div>}>
                    {data.map((d, index) => (
                      <AdminCatalogueRowonehundredfo
                        {...d}
                        key={"listonehundredf" + index}
                        className="w-[21%] md:w-full sm:p-5"
                      />
                    ))}
                  </Suspense>
                </div>
                <div className="flex gap-5 md:flex-col">
                  <div className="flex w-full flex-col items-start justify-center gap-[26px] rounded-[15px] bg-gray-900 p-6 sm:p-5">
                    <Heading as="h1">Revenus générés</Heading>
                    <div className="self-stretch">
                      <div className="flex items-start sm:flex-col">
                        <div className="relative z-[1] flex flex-col items-start gap-[29px]">
                          <Text size="caption" as="p">
                            16k
                          </Text>
                          <Text size="caption" as="p">
                            14k
                          </Text>
                          <Text size="caption" as="p">
                            12k
                          </Text>
                          <Text size="caption" as="p" className="h-[15px] w-[14px]">
                            8k
                          </Text>
                          <Text size="caption" as="p" className="h-[15px] w-[14px]">
                            4k
                          </Text>
                          <Text size="caption" as="p">
                            1k
                          </Text>
                        </div>
                        <div className="relative ml-[-4px] mt-[7px] flex flex-1 flex-col gap-2 sm:ml-0 sm:self-stretch">
                          <Img
                            src="images/img_group_197.png"
                            alt="image"
                            className="ml-3 h-[269px] object-cover md:ml-0"
                          />
                          <div className="flex flex-wrap">
                            <Text size="caption" as="p">
                              Janv
                            </Text>
                            <Text size="caption" as="p" className="ml-5">
                              Feb
                            </Text>
                            <Text size="caption" as="p" className="ml-[25px]">
                              Mar
                            </Text>
                            <Text size="caption" as="p" className="ml-[21px]">
                              Apr
                            </Text>
                            <Text size="caption" as="p" className="ml-[25px]">
                              May
                            </Text>
                            <Text size="caption" as="p" className="ml-5">
                              Jun
                            </Text>
                            <Text size="caption" as="p" className="ml-[27px] h-[15px] w-[15px]">
                              Jul
                            </Text>
                            <Text size="caption" as="p" className="ml-[31px]">
                              Aug
                            </Text>
                            <Text size="caption" as="p" className="ml-[18px]">
                              Sept
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full rounded-[15px] bg-gray-900 p-1.5">
                    <div className="mb-[17px] mt-3.5">
                      <div className="flex flex-col">
                        <div className="flex items-center gap-[15px] sm:flex-col">
                          <div className="flex flex-1 items-center justify-between gap-5 sm:self-stretch">
                            <Heading as="h2">Nombre de téléchargements</Heading>
                            <div className="flex w-[15%] items-center justify-center gap-2">
                              <div className="h-[8px] w-[8px] rounded bg-deep_purple-a200" />
                              <Text size="caption" as="p">
                                Gratuit
                              </Text>
                            </div>
                          </div>
                          <div className="flex w-[13%] items-start gap-2 self-end sm:w-full sm:self-auto">
                            <div className="h-[8px] w-[8px] rounded bg-deep_purple-a100" />
                            <Text size="caption" as="p">
                              Payant
                            </Text>
                          </div>
                        </div>
                        <div className="mt-[29px]">
                          <div className="flex items-end sm:flex-col">
                            <div className="flex flex-col items-start gap-[29px]">
                              <Text size="caption" as="p">
                                16k
                              </Text>
                              <Text size="caption" as="p">
                                14k
                              </Text>
                              <Text size="caption" as="p">
                                12k
                              </Text>
                              <Text size="caption" as="p" className="h-[15px] w-[14px]">
                                8k
                              </Text>
                              <Text size="caption" as="p" className="h-[15px] w-[14px]">
                                4k
                              </Text>
                              <Text size="caption" as="p">
                                1k
                              </Text>
                            </div>
                            <div className="ml-5 h-[224px] w-[6px] rounded-[3px] bg-deep_purple-a200 sm:ml-0 sm:h-[6px] sm:w-[224px]" />
                            <div className="h-[118px] w-[6px] rounded-[3px] bg-deep_purple-a100 sm:h-[6px] sm:w-[118px]" />
                            <div className="ml-[27px] h-[259px] w-[6px] rounded-[3px] bg-deep_purple-a200 sm:ml-0 sm:h-[6px] sm:w-[259px]" />
                            <div className="h-[206px] w-[6px] rounded-[3px] bg-deep_purple-a100 sm:h-[6px] sm:w-[206px]" />
                            <div className="ml-[27px] h-[161px] w-[6px] rounded-[3px] bg-deep_purple-a200 sm:ml-0 sm:h-[6px] sm:w-[161px]" />
                            <div className="h-[78px] w-[6px] rounded-[3px] bg-deep_purple-a100 sm:h-[6px] sm:w-[78px]" />
                            <div className="ml-[27px] h-[135px] w-[6px] rounded-[3px] bg-deep_purple-a200 sm:ml-0 sm:h-[6px] sm:w-[135px]" />
                            <div className="h-[97px] w-[6px] rounded-[3px] bg-deep_purple-a100 sm:h-[6px] sm:w-[97px]" />
                            <div className="ml-[27px] h-[240px] w-[6px] rounded-[3px] bg-deep_purple-a200 sm:ml-0 sm:h-[6px] sm:w-[240px]" />
                            <div className="h-[199px] w-[6px] rounded-[3px] bg-deep_purple-a100 sm:h-[6px] sm:w-[199px]" />
                            <div className="ml-[27px] h-[180px] w-[6px] rounded-[3px] bg-deep_purple-a200 sm:ml-0 sm:h-[6px] sm:w-[180px]" />
                            <div className="h-[151px] w-[6px] rounded-[3px] bg-deep_purple-a100 sm:h-[6px] sm:w-[151px]" />
                            <div className="ml-[27px] h-[161px] w-[6px] rounded-[3px] bg-deep_purple-a200 sm:ml-0 sm:h-[6px] sm:w-[161px]" />
                            <div className="h-[170px] w-[6px] rounded-[3px] bg-deep_purple-a100 sm:h-[6px] sm:w-[170px]" />
                            <div className="ml-[27px] h-[170px] w-[6px] rounded-[3px] bg-deep_purple-a200 sm:ml-0 sm:h-[6px] sm:w-[170px]" />
                            <div className="h-[209px] w-[6px] rounded-[3px] bg-deep_purple-a100 sm:h-[6px] sm:w-[209px]" />
                            <div className="ml-[27px] h-[180px] w-[6px] rounded-[3px] bg-deep_purple-a200 sm:ml-0 sm:h-[6px] sm:w-[180px]" />
                            <div className="h-full w-[6px] rounded-[3px] bg-deep_purple-a100 sm:h-[6px] sm:w-full" />
                            <div className="ml-[27px] h-[151px] w-[6px] rounded-[3px] bg-deep_purple-a200 sm:ml-0 sm:h-[6px] sm:w-[151px]" />
                            <div className="h-[236px] w-[6px] rounded-[3px] bg-deep_purple-a100 sm:h-[6px] sm:w-[236px]" />
                          </div>
                        </div>
                        <div className="mt-[7px] flex flex-wrap gap-[26px] self-end">
                          <Text size="caption" as="p">
                            Janv
                          </Text>
                          <Text size="caption" as="p">
                            Feb
                          </Text>
                          <Text size="caption" as="p">
                            Mar
                          </Text>
                          <Text size="caption" as="p">
                            Apr
                          </Text>
                          <Text size="caption" as="p">
                            May
                          </Text>
                          <Text size="caption" as="p">
                            Jun
                          </Text>
                          <Text size="caption" as="p" className="h-[15px] w-[15px]">
                            Jul
                          </Text>
                          <Text size="caption" as="p">
                            Aug
                          </Text>
                          <Text size="caption" as="p">
                            Sept
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-[5px]">
              <div>
                <div className="flex flex-col items-start gap-[33px]">
                  <Heading as="h3">Séquences les plus téléchargées</Heading>
                  <div className="flex items-center gap-[26px] self-stretch md:flex-col">
                    <div className="flex w-full gap-[26px] md:flex-row md:p-5 sm:flex-col">
                      <Suspense fallback={<div className="loader">Loading feed...</div>}>
                        {[...Array(2)].map((d, index) => (
                          <AdminCatalogueCardsquence1 key={"listtime" + index} />
                        ))}
                      </Suspense>
                    </div>
                    <div className="flex w-full md:flex-col md:p-5">
                      <Suspense fallback={<div className="loader">Loading feed...</div>}>
                        {[...Array(2)].map((d, index) => (
                          <AdminCatalogueRowlaudition key={"listtime1" + index} className="flex-1 sm:flex-col" />
                        ))}
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-[79%] flex-col items-start gap-[54px] md:w-full md:p-5 sm:gap-[27px]">
                <Heading as="h4">Droits bientôt expirés</Heading>
                <ReactTable
                  size="xs"
                  variant="simple"
                  bodyProps={{ className: "" }}
                  headerProps={{ className: "md:flex-col" }}
                  rowDataProps={{ className: "bg-gray-900 md:flex-col rounded-[13px]" }}
                  className="sm:whitespace-no-wrap self-stretch sm:block sm:overflow-x-auto"
                  columns={tableColumns}
                  data={tableData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
